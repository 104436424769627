import { useQuery } from "@tanstack/react-query";
import { useClientOnboardingApi } from "./ClientOnboardingApi";
import { ClientType, OptionsApiFunctionKeys } from "../api";

export const useBusinessCodeOptionsGet = () => {
  const { OptionsApi } = useClientOnboardingApi();

  return useQuery({
    queryKey: [OptionsApiFunctionKeys.optionsBusinessCodeGet],
    queryFn: () => OptionsApi.optionsBusinessCodeGet(),
    select: (res) => res.data ?? [],
  });
};

export const useBusinessUnitOptionsGet = () => {
  const { OptionsApi } = useClientOnboardingApi();

  return useQuery({
    queryKey: [OptionsApiFunctionKeys.optionsBusinessUnitGet],
    queryFn: () => OptionsApi.optionsBusinessUnitGet(),
    select: (res) => res.data ?? [],
  });
};

export const useLineOfBusinessOptionsGet = () => {
  const { OptionsApi } = useClientOnboardingApi();

  return useQuery({
    queryKey: [OptionsApiFunctionKeys.optionsLineOfBusinessGet],
    queryFn: () => OptionsApi.optionsLineOfBusinessGet(),
    select: (res) => res.data ?? [],
  });
};

export const useStaffOptionsGet = () => {
  const { OptionsApi } = useClientOnboardingApi();

  return useQuery({
    queryKey: [OptionsApiFunctionKeys.optionsStaffGet],
    queryFn: () => OptionsApi.optionsStaffGet(),
    select: (res) => res.data ?? [],
  });
};

export const useOfficeOptionsGet = () => {
  const { OptionsApi } = useClientOnboardingApi();

  return useQuery({
    queryKey: [OptionsApiFunctionKeys.optionsOfficeGet],
    queryFn: () => OptionsApi.optionsOfficeGet(),
    select: (res) => res.data ?? [],
  });
};

export const useClientTypeOptionsGet = (clientType: ClientType | undefined) => {
  const { OptionsApi } = useClientOnboardingApi();

  return useQuery({
    queryKey: [OptionsApiFunctionKeys.getOptionsClientType, clientType],
    queryFn: () =>
      OptionsApi.getOptionsClientType({
        clientType: clientType ?? ClientType.Individual,
      }),
    select: (res) => res.data ?? [],
    enabled: !!clientType,
  });
};
