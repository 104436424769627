import { Button, Caption1, Card, CardHeader, makeStyles, Text, tokens } from '@fluentui/react-components';
import { MoreHorizontal20Regular } from '@fluentui/react-icons';

const useStyles = makeStyles({
    main: {
        gap: '36px',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },

    card: {
        width: '360px',
        maxWidth: '100%',
        height: 'fit-content',
    },

    section: {
        width: 'fit-content',
    },

    title: { margin: '0 0 12px' },

    horizontalCardImage: {
        width: '64px',
        height: '64px',
    },

    headerImage: {
        borderRadius: '4px',
        maxWidth: '44px',
        maxHeight: '44px',
    },

    caption: {
        color: tokens.colorNeutralForeground3,
    },

    text: { margin: '0' },
});

export const ConsentCard = () => {
    const styles = useStyles();

    return (
        <Card className={styles.card}>
            <CardHeader
                image={<img className={styles.headerImage} src={'ATA_LOGO-B-RGB.svg'} alt="ATA Client Onboarding" />}
                header={<Text weight="semibold">Client Onboarding</Text>}
                description={<Caption1 className={styles.caption}>ATA Advisory LLC / ATA Digital</Caption1>}
                action={
                    <Button appearance="transparent" icon={<MoreHorizontal20Regular />} aria-label="More options" />
                }
            />

            <p className={styles.text}>
                Since this is your first time you have accessed the Client Onboarding app, you must authorize its use.
                Please click the Authorize button to continue.
            </p>
        </Card>
    );
};
