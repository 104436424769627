import {
  differenceInDays,
  differenceInHours,
  format,
  isToday,
  isYesterday,
  parseISO,
} from "date-fns";

export function generateDateLabel(date: string | Date | undefined): string {
  if (!date) {
    return "--";
  }

  const now: Date = new Date(); // Current date and time
  const givenDate: Date = typeof date === "string" ? parseISO(date) : date; // Handle both string and Date types

  if(givenDate.getFullYear() <= 1970) {
    return "--";
  }

  const diffInHours: number = differenceInHours(now, givenDate);
  const diffInDays: number = differenceInDays(now, givenDate);

  if (diffInHours < 24) {
    if (diffInHours < 1) {
      const diffInMinutes: number = Math.max(
        1,
        Math.round((now.getTime() - givenDate.getTime()) / 60000),
      ); // Calculate minutes
      return `${diffInMinutes}m ago`;
    }
    return `${diffInHours}h ago`;
  }

  if (isToday(givenDate)) {
    return `Today at ${format(givenDate, "p")}`; // 'p' is the time format, e.g., "1:45 PM"
  }

  if (isYesterday(givenDate)) {
    return `Yesterday at ${format(givenDate, "p")}`;
  }

  if (diffInDays < 7) {
    return `${format(givenDate, "EEE")} at ${format(givenDate, "p")}`; // 'EEE' gives short weekday name like "Tue"
  }

  return format(givenDate, "MMM d, yyyy 'at' p"); // e.g., "Jan 4, 2023 at 1:45 PM"
}
