import {
    Button,
    Card,
    CardHeader,
    Divider,
    Label,
    MessageBar,
    MessageBarBody,
    MessageBarTitle,
    Spinner,
    Subtitle1,
    Text,
} from '@fluentui/react-components';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientsApiFunctionKeys } from '../../api';
import { useClientOnboardingApi } from '../../api/hooks/ClientOnboardingApi';
import {
    useBusinessUnitOptionsGet,
    useLineOfBusinessOptionsGet,
    useOfficeOptionsGet,
    useStaffOptionsGet,
} from '../../api/hooks/OptionsApi';
import { ClientOnboardingFormAtom, DefaultClientOnboardingForm } from '../../store/ClientOnboardingFormAtom';
import { addressPrinter } from '../../utils/printerUtils';
import TopBar from '../common/TopBar';
import Container from '../layout/Container';
import Stack from '../layout/Stack';

const PreviewRow = ({ label, value }: { label: string; value: string | undefined }) => {
    return (
        <div>
            <Label weight="semibold" style={{ paddingRight: 5 }}>
                {label}:
            </Label>
            <Text>{value}</Text>
        </div>
    );
};
const ReviewPage = () => {
    const [clientOnboardingForm, setClientOnboardingForm] = useAtom(ClientOnboardingFormAtom);
    const { clientType, generalSection, contactSection, adminSection } = clientOnboardingForm;
    const { data: businessUnitOptions } = useBusinessUnitOptionsGet();
    const { data: lineOfBusinessOptions } = useLineOfBusinessOptionsGet();
    const { data: staffOptions } = useStaffOptionsGet();
    const { data: officeOptions } = useOfficeOptionsGet();
    const queryClient = useQueryClient();

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { ClientsApi } = useClientOnboardingApi();
    const isIndividualClient = clientType === 'Individual' || clientType === 'Staff';

    // Submit form
    const submitToBackend = () => {
        // Reset submitting and error state
        setSubmitting(true);
        setSubmitError(null);

        // Trim Dates
        const trimmedForm = {
            ...clientOnboardingForm,
            adminSection: {
                ...clientOnboardingForm.adminSection,
                yearEnd: adminSection.yearEnd.replace('/', ''),
                rainmakerDate: format(adminSection.rainmakerDate, 'yyyy-MM-dd'),
            },
        };

        // Submit form
        ClientsApi.clientsPost({ clientOnboardingForm: trimmedForm })
            .then((response) => {
                console.log('Response', response);

                // Reset to default
                setClientOnboardingForm(DefaultClientOnboardingForm);
                queryClient.invalidateQueries({ queryKey: [ClientsApiFunctionKeys.clientsGet], exact: false });
                navigate('/ClientSuccess', { replace: true, state: { client: response.data } });
            })
            .catch((error) => {
                setSubmitError('There was an error creating the client. Please try again.');
                console.error('Error', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const BackToForm = () => {
        navigate('/FormPage');
    };

    return (
        <Container>
            <TopBar />
            <Stack flexDirection="column">
                <Card>
                    <CardHeader header={<Text size={700}>Review Client Information</Text>} />
                    <Subtitle1>Client's General Information</Subtitle1>
                    {!isIndividualClient && (
                        <PreviewRow label="Business Name" value={generalSection.businessSection.businessName} />
                    )}
                    <PreviewRow
                        label="Business Unit"
                        value={businessUnitOptions?.find((b) => b.id === generalSection.businessUnitId)?.name}
                    />
                    <PreviewRow
                        label="Office"
                        value={officeOptions?.find((b) => b.id === generalSection.officeId)?.name}
                    />
                    {isIndividualClient && (
                        <>
                            <PreviewRow label="First Name" value={generalSection.individualSection.firstName} />
                            <PreviewRow label="Last Name" value={generalSection.individualSection.lastName} />
                        </>
                    )}
                    <Divider alignContent="start" />
                    <Subtitle1>Contact Information</Subtitle1>
                    <PreviewRow label="Home/Business Address" value={addressPrinter(contactSection.mainAddress)} />
                    <PreviewRow label="Mailing Address" value={addressPrinter(contactSection.mailingAddress)} />
                    <PreviewRow label="Main Phone" value={contactSection.mainPhone} />
                    <PreviewRow label="Main Email" value={contactSection.mainEmail} />
                    <Divider alignContent="start" />
                    <Subtitle1>Administrative Information</Subtitle1>
                    <PreviewRow label="Year End" value={adminSection.yearEnd} />
                    <PreviewRow
                        label="Line of Business"
                        value={lineOfBusinessOptions?.find((p) => p.id === adminSection.lineOfBusinessId)?.name}
                    />
                    <PreviewRow
                        label="Primary Partner"
                        value={staffOptions?.find(({ id }) => id === adminSection.primaryPartnerId)?.name}
                    />
                    <PreviewRow
                        label="Rainmaker"
                        value={staffOptions?.find(({ id }) => id === adminSection.rainmakerId)?.name}
                    />
                    {!isIndividualClient && generalSection.businessSection.dateIncorporated && (
                        <>
                            <PreviewRow label="Business Code" value={generalSection.businessSection.businessCode} />
                            <PreviewRow
                                label="RainmakDate Incorporateder"
                                value={generalSection.businessSection.dateIncorporated}
                            />
                        </>
                    )}
                </Card>
                <Stack justifyContent="space-between" alignItems="center">
                    <Button
                        type="button"
                        appearance="primary"
                        icon={submitting ? <Spinner size="tiny" /> : null}
                        disabledFocusable={submitting}
                        onClick={submitToBackend}
                    >
                        Create Client
                    </Button>
                    {submitError && (
                        <MessageBar key="submitError" intent="error">
                            <MessageBarBody>
                                <MessageBarTitle>Error Creating Client</MessageBarTitle>
                                {submitError}
                            </MessageBarBody>
                        </MessageBar>
                    )}
                    <Button appearance="outline" onClick={BackToForm} disabledFocusable={submitting}>
                        Back
                    </Button>
                </Stack>
            </Stack>
        </Container>
    );
};

export default ReviewPage;
