import { Button, Title2, makeStyles } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import ResponsiveRowWrapper from "../layout/ResponsiveRowWrapper";
import Stack from "../layout/Stack";

const useStyles = makeStyles({
  stackRow: {
    justifyContent: "space-between",
    paddingBottom: "1em",
  },
});

const TopBar = () => {
  const navigate = useNavigate();
  const styles = useStyles();

  return (
    <ResponsiveRowWrapper className={styles.stackRow}>
      <Title2>Client Onboarding Form</Title2>

      <Stack justifyContent="flex-end">
        <Button onClick={() => navigate("/Recent")}>
          Return to Client List
        </Button>
      </Stack>
    </ResponsiveRowWrapper>
  );
};

export default TopBar;
