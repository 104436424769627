import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ClientsApiClientsGetRequest,
  ClientsApiClientsPostRequest,
  ClientsApiFunctionKeys,
} from "../api";
import { useClientOnboardingApi } from "./ClientOnboardingApi";

export const useClientsGet = (
  requestParameters?: ClientsApiClientsGetRequest,
) => {
  const { ClientsApi } = useClientOnboardingApi();
  const { sort, order, search, page, pageSize } = requestParameters ?? {};

  return useQuery({
    queryKey: [
      ClientsApiFunctionKeys.clientsGet,
      sort,
      order,
      search,
      page,
      pageSize,
    ],
    queryFn: () =>
      ClientsApi.clientsGet({ sort, order, search, page, pageSize }),
    select: (res) => res.data ?? [],
    // refetch every 30seconds
    refetchInterval: 30000,
  });
};

export const useClientsPost = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { ClientsApi } = useClientOnboardingApi();

  return useMutation({
    mutationKey: [ClientsApiFunctionKeys.clientsPost],
    mutationFn: (requestParameters: ClientsApiClientsPostRequest) =>
      ClientsApi.clientsPost(requestParameters),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["ClientsApi.clientsGet"],
      });

      onSuccess && onSuccess();
    },
  });
};
