import { FieldValues, RegisterOptions } from "react-hook-form";

export const getDefaultErrorMessage = <T extends FieldValues>(
  type: string,
  rules?: Omit<
    RegisterOptions<T>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >,
) => {
  switch (type) {
    case "required":
      return "This field is required";
    case "min":
      return `This field must be at least ${rules?.min}`;
    case "max":
      return `This can't be greater than ${rules?.max}`;
    case "minLength":
      return `This field must be at least ${rules?.minLength} characters`;
    case "maxLength":
      return `This field must be at most ${rules?.maxLength} characters`;
    case "pattern":
      return "This field is invalid";
    default:
      return "";
  }
};
