import { Field, Input, InputProps } from "@fluentui/react-components";
import {
  FieldValues,
  get,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { NumberFormatValues, PatternFormat } from "react-number-format";

type ZipInputProps<T extends FieldValues> = UseControllerProps<T> &
  InputProps & {
    label?: string;
  };

const ZipInput = <T extends FieldValues>(props: ZipInputProps<T>) => {
  const { name, control, rules, defaultValue, label } = props;

  const {
    field,
    formState: { errors },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const handleValueChange = (input: NumberFormatValues) => {
    const plainValue = input.value;
    field.onChange(plainValue);
  };

  const error = get(errors, name);

  const errorMessage = error
    ? error.message.length > 0
      ? error.message
      : "This field is required"
    : undefined;

  return (
    <Field
      validationMessage={errorMessage}
      label={label}
      required={(rules?.required as boolean) ?? false}
    >
      <PatternFormat
        format="#####"
        customInput={Input}
        value={field.value}
        onValueChange={handleValueChange}
        onBlur={field.onBlur}
        appearance="filled-darker"
        allowEmptyFormatting
        mask="#"
      />
    </Field>
  );
};

export default ZipInput;
