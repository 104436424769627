import { Button, Card, CardHeader, makeStyles, Text } from '@fluentui/react-components';
import { CheckmarkCircleRegular } from '@fluentui/react-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClientCreationResponse } from '../../api';
import Container from '../layout/Container';
import Stack from '../layout/Stack';

const useStyles = makeStyles({
    containerHeight: {
        height: '70%',
    },
    cardContainer: {
        width: '50%',

        '@media (max-width: 768px)': {
            width: '100%',
        },
    },
    buttonMargin: {
        marginTop: '1rem',
    },
});

const ClientSuccess = () => {
    const styles = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const creationResponse = location.state.client as ClientCreationResponse;

    const returnToClientList = () => {
        navigate('/Recent');
    };

    console.log('state', location.state);

    return (
        <Container>
            <Stack alignItems="center" className={styles.containerHeight}>
                <Card className={styles.cardContainer}>
                    <div
                        style={{
                            placeItems: 'center',
                        }}
                    >
                        <CheckmarkCircleRegular fontSize={64} color="green" />
                        <CardHeader
                            header={
                                <Text size={700} align="center">
                                    Sucessfully Created Client
                                </Text>
                            }
                        />
                        <Stack flexDirection="column" justifyContent="space-between">
                            <Text size={500}>
                                <b>Name:</b> {creationResponse.name}
                            </Text>

                            <Text size={500}>
                                <b>Client ID:</b> {creationResponse.clientId}
                            </Text>
                        </Stack>
                        <Button appearance="primary" onClick={returnToClientList} className={styles.buttonMargin}>
                            Return to Client List
                        </Button>
                    </div>
                </Card>
            </Stack>
        </Container>
    );
};

export default ClientSuccess;
