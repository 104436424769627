import {
  Field,
  Input,
  InputProps,
} from "@fluentui/react-components";
import {
  FieldValues,
  get,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { getDefaultErrorMessage } from "./FormErrorHelper";
import InputWrapper from "../layout/InputWrapper";

type TextInputProps<T extends FieldValues> = UseControllerProps<T> &
  InputProps & {
    label?: string;
    className?: string;
    minWidth?: number;
  };

const TextInput = <T extends FieldValues>(props: TextInputProps<T>) => {
  const {
    name,
    control,
    rules,
    defaultValue,
    label,
    className,
    minWidth,
    ...rest
  } = props;

  const {
    field,
    formState: { errors },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  // Get possible Error object and message
  const error = get(errors, props.name);
  const errorMessage = error
    ? error.message.length > 0
      ? error.message
      : getDefaultErrorMessage(error.type, rules)
    : "";

  console.log("errorMessage ", errorMessage);

  return (
    <InputWrapper>
      <Field
        validationMessage={errorMessage}
        className={className}
        label={label}
        required={(rules?.required as boolean) ?? false}
      >
        <Input {...field} {...rest} appearance="filled-darker" />
      </Field>
    </InputWrapper>
  );
};

export default TextInput;
