import {
    Button,
    FluentProvider,
    Spinner,
    teamsDarkTheme,
    teamsHighContrastTheme,
    teamsLightTheme,
    tokens,
} from '@fluentui/react-components';
import { CheckboxArrowRightRegular } from '@fluentui/react-icons';
import { Providers, ProviderState } from '@microsoft/mgt-react';
import { TeamsFxProvider } from '@microsoft/mgt-teamsfx-provider';
import { TeamsUserCredential, TeamsUserCredentialAuthConfig } from '@microsoft/teamsfx';
import { useTeamsUserCredential } from '@microsoft/teamsfx-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { HashRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { ConsentCard } from './ConsentCard';
import { TeamsFxContext } from './Context';
import Container from './layout/Container';
import ClientSuccess from './routes/ClientSuccess';
import FormPage from './routes/FormPage';
import { RecentClientsTable } from './routes/RecentTable';
import ReviewPage from './routes/ReviewPage';

const queryClient = new QueryClient();

export const authConfig: TeamsUserCredentialAuthConfig = {
    clientId: process.env.REACT_APP_CLIENT_ID!,
    initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL!,
};

const scopes = ['User.Read'];
const credential = new TeamsUserCredential(authConfig);
const provider = new TeamsFxProvider(credential, scopes);
Providers.globalProvider = provider;

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
    const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential(authConfig);
    const [consentNeeded, setConsentNeeded] = useState<boolean>(false);

    useEffect(() => {
        const init = async () => {
            try {
                await credential.getToken(scopes);
                Providers.globalProvider.setState(ProviderState.SignedIn);
            } catch (error) {
                console.log('Caught consent/token error', error);
                setConsentNeeded(true);
            }
        };

        init();
    }, []);

    const consent = async () => {
        // setLoading(true);
        await credential.login(scopes);
        Providers.globalProvider.setState(ProviderState.SignedIn);
        // setLoading(false);
        setConsentNeeded(false);
    };

    return (
        <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
            <QueryClientProvider client={queryClient}>
                <FluentProvider
                    theme={
                        themeString === 'dark'
                            ? teamsDarkTheme
                            : themeString === 'contrast'
                              ? teamsHighContrastTheme
                              : {
                                    ...teamsLightTheme,
                                    colorNeutralBackground3: '#eeeeee',
                                }
                    }
                    style={{ background: tokens.colorNeutralBackground3 }}
                >
                    {consentNeeded ? (
                        <>
                            <Container>
                                <ConsentCard />
                                <br />
                                <Button
                                    appearance="primary"
                                    disabled={loading}
                                    onClick={consent}
                                    icon={<CheckboxArrowRightRegular />}
                                >
                                    Authorize Client Onboarding
                                </Button>
                            </Container>
                        </>
                    ) : (
                        <Router>
                            {loading ? (
                                <Spinner style={{ margin: 100 }} />
                            ) : (
                                <Routes>
                                    <Route path="/FormPage" element={<FormPage />} />
                                    <Route path="/ReviewPage" element={<ReviewPage />} />
                                    <Route path="/ClientSuccess" element={<ClientSuccess />} />
                                    <Route path="/Recent" element={<RecentClientsTable />}></Route>
                                    <Route path="*" element={<Navigate to={'/Recent'} />}></Route>
                                </Routes>
                            )}
                        </Router>
                    )}
                </FluentProvider>
            </QueryClientProvider>
        </TeamsFxContext.Provider>
    );
}
