import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        width: 'auto',
        height: 'auto',
        boxSizing: 'border-box',
        marginTop: '1rem',
        gap: '1rem',
        '> *': {
            textOverflow: 'ellipsis',
        },
        '> :not(:first-child)': {
            marginTop: '0px',
        },
        '> *:not(.ms-StackItem)': {
            flexShrink: 1,
        },
    },
});

interface StackProps {
    children: React.ReactNode;
    flexDirection?: 'row' | 'column';
    justifyContent?: React.CSSProperties['justifyContent'];
    alignItems?: React.CSSProperties['alignItems'];
    style?: React.CSSProperties | undefined;
    className?: string;
}

const Stack = ({
    children,
    flexDirection = 'row',
    justifyContent = 'center',
    alignItems = 'flex-start',
    style,
    className,
}: StackProps) => {
    const styles = useStyles();
    return (
        <div
            className={mergeClasses(styles.root, className)}
            style={{ ...style, justifyContent, alignItems, flexDirection }}
        >
            {children}
        </div>
    );
};

export default Stack;
