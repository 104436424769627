import { ReactNode } from "react";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    padding: "1rem",
    minHeight: "100vh",
  },
});

interface ContainerProps {
  children: ReactNode;
}

const Container = ({ children }: ContainerProps) => {
  const styles = useStyles();

  return <div className={styles.container}>{children}</div>;
};

export default Container;
