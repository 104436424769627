import { DateRangeType } from "@fluentui/react-calendar-compat";
import { Field } from "@fluentui/react-components";
import { DatePicker as FluentDatePicker } from "@fluentui/react-datepicker-compat";
import { format, isValid, parseISO } from "date-fns";
import * as React from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import InputWrapper from "../layout/InputWrapper";

const onFormatDate = (date?: Date | string): string => {
  if (date) {
    const newDate: Date =
      typeof date === "string"
        ? parseISO(date)
        : isValid(date)
          ? date
          : new Date();
    return format(newDate, "MM/dd/yyyy");
  }
  return "";
};

type DayMonthPickerProps<T extends FieldValues> = UseControllerProps<T> & {
  label?: string;
};

const DatePicker = <T extends FieldValues>(props: DayMonthPickerProps<T>) => {
  const { name, control, rules, defaultValue, label } = props;

  const {
    field,
    formState: { errors },
  } = useController({ name, control, rules, defaultValue });

  const [internalSelectedDate, setInternalSelectedDate] = React.useState<
    Date | null | undefined
  >(field.value ? parseISO(field.value) : null);

  const datePickerRef = React.useRef<HTMLInputElement>(null);

  const onSelectDate = (date: Date | null | undefined) => {
    // Set the internal date
    setInternalSelectedDate(date);
    // Set the field value to a ISO string based off the date
    field.onChange(date?.toISOString());
  };

  const onParseDateFromString = React.useCallback(
    (newValue: string): Date => {
      const parsedDate = parseISO(newValue);
      if (isValid(parsedDate)) {
        return parsedDate;
      } else {
        return new Date();
      }
    },
    [internalSelectedDate],
  );

  return (
    <InputWrapper>
      <Field
        label={label ?? "Pick Date"}
        required={(rules?.required as boolean) ?? false}
      >
        <FluentDatePicker
          ref={datePickerRef}
          allowTextInput={false}
          value={internalSelectedDate}
          onSelectDate={onSelectDate}
          formatDate={onFormatDate}
          parseDateFromString={onParseDateFromString}
          placeholder="Select a date..."
          calendar={{
            dateRangeType: DateRangeType.Day,
            showGoToToday: false,
            highlightSelectedMonth: false,
          }}
          appearance="filled-darker"
        />
      </Field>
    </InputWrapper>
  );
};

export default DatePicker;
