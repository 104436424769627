import { makeStyles, Toolbar, ToolbarButton } from "@fluentui/react-components";
import {
  FontIncrease24Regular,
  FontDecrease24Regular,
  TextFont24Regular,
} from "@fluentui/react-icons";
import { set } from "lodash";
import React from "react";

type PaginationToolbarProps = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const useStyles = makeStyles({
  toolbar: {
    marginTop: "8px",
    flexDirection: "row-reverse",
  },
  numberButton: {
    margin: "4px",
    borderRadius: "8px",
    minWidth: "32px",
    minHeight: "32px",
    fontSize: "12px",
  },
});

const PaginationToolbar = ({ page, setPage }: PaginationToolbarProps) => {
  const styles = useStyles();

  const handleNextBtn = () => {
    setPage((prev) => prev + 1);
  };

  const handlePrevBtn = () => {
    setPage((prev) => (prev >= 1 ? prev - 1 : 0));
  };

  return (
    <Toolbar aria-label="Small" size="small" className={styles.toolbar}>
      {/* The order is reversed, top will be last in this div */}
      <ToolbarButton onClick={handleNextBtn} className={styles.numberButton}>
        Next
      </ToolbarButton>
      <ToolbarButton
        disabled={page === 0}
        onClick={handlePrevBtn}
        className={styles.numberButton}
      >
        Prev
      </ToolbarButton>
    </Toolbar>
  );
};

export default PaginationToolbar;
