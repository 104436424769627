import {
  Dropdown,
  Field,
  SelectProps,
  Option,
  SelectionEvents,
  OptionOnSelectData,
} from "@fluentui/react-components";
import {
  FieldValues,
  get,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { getDefaultErrorMessage } from "./FormErrorHelper";
import InputWrapper from "../layout/InputWrapper";
import { OptionGuidResponse } from "../../api";
import { useCallback, useMemo, useState } from "react";

export type IATADSelectOption = {
  value: string;
  label: string;
};

type IATADSelectInput<T extends FieldValues> = UseControllerProps<T> &
  SelectProps & {
    label?: string;
    options?: OptionGuidResponse[];
    errorText?: string;
  };

const ATADSelectInput = <T extends FieldValues>(props: IATADSelectInput<T>) => {
  const { name, control, rules, defaultValue, label, options } = props;
  const {
    field,
    formState: { errors },
  } = useController<T>({ name, control, rules, defaultValue });

  // fn to get the name from the id which is the field value, using this over local state since it's more performant
  const nameFromId = useCallback(
    (fieldValue: string) => {
      const option = options?.find((option) => option.id === fieldValue);
      return option?.name;
    },
    [options],
  );

  // Get possible Error object and message
  const error = get(errors, props.name);
  const errorMessage = error
    ? error.message.length > 0
      ? error.message
      : getDefaultErrorMessage(error.type, rules)
    : "";

  const onClearButtonClick = () => {
    field.onChange("");
  };

  const onOptionSelect = (
    _event: SelectionEvents,
    data: OptionOnSelectData,
  ) => {
    // Set the field value to the selected option
    field.onChange(data.optionValue);
  };

  return (
    <InputWrapper>
      <Field
        label={label}
        validationMessage={errorMessage}
        required={(rules?.required as boolean) ?? false}
      >
        <Dropdown
          {...field}
          style={{ overflow: "hidden" }}
          clearable={true}
          clearButton={{ onClick: onClearButtonClick }}
          onOptionSelect={onOptionSelect}
          selectedOptions={[field.value]}
          // The string for "Select Option" is for 2 issues,
          // 1: Undefined value causing React errors,
          // 2: The dropdown shrinks to fit the text, meaning a blank value shrinks the dropdown.
          value={nameFromId(field.value) ?? "Select Option"}
          size="medium"
        >
          {/* Below is my hack of just wrapping it in a div. Which works but doesn't feel like the right way to solve this. */}
          {/* Watch https://github.com/microsoft/fluentui/discussions/33266 for updates from team. */}
          <div style={{ maxHeight: "40vh", overflow: "auto" }}>
            {options?.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </div>
        </Dropdown>
      </Field>
    </InputWrapper>
  );
};

export default ATADSelectInput;
