import { Button } from '@fluentui/react-components';
import { useAtom } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ClientOnboardingForm, ClientType } from '../../api';
import { ClientOnboardingFormAtom } from '../../store/ClientOnboardingFormAtom';
import TopBar from '../common/TopBar';
import AddressPhoneCard from '../form/Pages/AddressPhoneCard';
import AdminCard from '../form/Pages/AdminCard';
import ClientTypeCard from '../form/Pages/ClientTypeCard';
import GeneralInfoCard from '../form/Pages/GeneralInfoCard';
import Container from '../layout/Container';
import Stack from '../layout/Stack';

const FormPage = () => {
    const [clientOnboardingForm, setClientOnboardingForm] = useAtom(ClientOnboardingFormAtom);
    const navigate = useNavigate();

    // Initialize form
    const methods = useForm<ClientOnboardingForm>({
        defaultValues: clientOnboardingForm,
        mode: 'onBlur',
    });

    // Submit the Form, then navigate to the ReviewPage
    const onSubmit = (data: ClientOnboardingForm) => {
        setClientOnboardingForm(data);
        navigate('/ReviewPage');
    };

    // Watch ClientType for conditional rendering
    const clientType = methods.watch('clientType') as ClientType;

    // Render
    return (
        <Container>
            <TopBar />
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Stack flexDirection="column">
                        <ClientTypeCard />
                        <GeneralInfoCard clientType={clientType} />
                        <AddressPhoneCard clientType={clientType} />
                        <AdminCard clientType={clientType} />
                    </Stack>
                    <Stack justifyContent="flex-start" alignItems="center">
                        <Button type="submit" appearance="primary">
                            Create Client
                        </Button>
                    </Stack>
                </form>
            </FormProvider>
        </Container>
    );
};

export default FormPage;
