import { FieldValues, Path, UseControllerProps } from "react-hook-form";
import StateCombo from "./StateCombo";
import TextInput from "./TextInput";
import ZipInput from "./ZipInput";
import ResponsiveRowWrapper from "../layout/ResponsiveRowWrapper";

type AddressRowProps<T extends FieldValues> = UseControllerProps<T> & {};

const AddressRow = <T extends FieldValues>({
  name,
  control,
}: AddressRowProps<T>) => {
  return (
    <>
      <ResponsiveRowWrapper>
        <TextInput
          control={control}
          name={`${name}.streetLine` as Path<T>}
          label="Street Line"
          rules={{ required: true }}
        />
        <TextInput
          control={control}
          name={`${name}.streetLine2` as Path<T>}
          label="Street Line 2"
        />
      </ResponsiveRowWrapper>
      <ResponsiveRowWrapper>
        <TextInput
          control={control}
          name={`${name}.city` as Path<T>}
          label="City"
          rules={{ required: true }}
        />
        <StateCombo
          control={control}
          name={`${name}.state` as Path<T>}
          rules={{ required: true }}
        />
        <ZipInput
          control={control}
          name={`${name}.zip` as Path<T>}
          label="Zip"
          rules={{ required: true }}
        />
      </ResponsiveRowWrapper>
    </>
  );
};

export default AddressRow;
