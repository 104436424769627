import { Field, Input, InputProps } from "@fluentui/react-components";
import {
  FieldValues,
  get,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { NumberFormatValues, PatternFormat } from "react-number-format";
import { getDefaultErrorMessage } from "./FormErrorHelper";
import InputWrapper from "../layout/InputWrapper";

type PhoneInputProps<T extends FieldValues> = UseControllerProps<T> &
  InputProps & {
    label?: string;
  };

const StyledInput = (props: InputProps) => {
  return <Input {...props} style={{}} />;
};

const PhoneInputField = <T extends FieldValues>(props: PhoneInputProps<T>) => {
  const { name, control, rules, defaultValue, label } = props;

  const {
    field,
    formState: { errors },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const handleValueChange = (input: NumberFormatValues) => {
    const plainValue = input.value;
    field.onChange(plainValue);
  };

  // Get possible Error object and message
  const error = get(errors, props.name);
  const errorMessage = error
    ? error.message.length > 0
      ? error.message
      : getDefaultErrorMessage(error.type, rules)
    : "";

  return (
    <InputWrapper>
      <Field
        validationMessage={errorMessage}
        label={label}
        required={(rules?.required as boolean) ?? false}
      >
        <PatternFormat
          {...field}
          format="(###) ### ####"
          customInput={StyledInput}
          onValueChange={handleValueChange}
          appearance="filled-darker"
          allowEmptyFormatting
          mask="#"
        />
      </Field>
    </InputWrapper>
  );
};

export default PhoneInputField;
