import { formatISO } from 'date-fns';
import { atom } from 'jotai';
import { ClientOnboardingForm, ClientType } from '../api';

export const DefaultClientOnboardingForm: ClientOnboardingForm = {
    clientType: ClientType.Individual,
    // subClientType: "",
    generalSection: {
        businessUnitId: '',
        officeId: '',
        businessSection: {
            businessName: '',
            businessCode: '',
            dateIncorporated: undefined,
            principalBusinessActivity: '',
            principalProductService: '',
        },
        individualSection: {
            firstName: '',
            middleName: undefined,
            lastName: '',
            spouseFirstName: '',
            spouseMiddleName: undefined,
            spouseLastName: '',
        },
    },
    contactSection: {
        mailingAddress: {
            streetLine: '',
            streetLine2: undefined,
            city: '',
            state: '',
            zip: '',
        },
        mainAddress: {
            streetLine: '',
            streetLine2: '',
            city: '',
            state: '',
            zip: '',
        },
        mainEmail: '',
        mainPhone: '',
        mobilePhone: '',
    },
    adminSection: {
        yearEnd: '12/31',
        lineOfBusinessId: '',
        primaryPartnerId: '',
        billerId: '',
        rainmakerId: '',
        rainmakerDate: formatISO(new Date()),
        businessCode: '',
    },
};

export const ClientOnboardingFormAtom = atom<ClientOnboardingForm>(DefaultClientOnboardingForm);
