import {
    Combobox,
    Field,
    Input,
    Option,
    OptionOnSelectData,
    SelectionEvents,
    Spinner,
} from '@fluentui/react-components';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues, get, useController, UseControllerProps } from 'react-hook-form';
import { OptionGuidResponse } from '../../api';
import InputWrapper from '../layout/InputWrapper';

type OptionTypeComboProps<T extends FieldValues> = UseControllerProps<T> & {
    options: OptionGuidResponse[] | undefined;
    doneLoading: boolean;
    label: string;
};

const OptionTypeCombo = <T extends FieldValues>(props: OptionTypeComboProps<T>) => {
    const { name, control, rules, defaultValue, options: dropdownOptions, doneLoading: optionsLoaded, label } = props;

    const {
        field,
        formState: { errors },
    } = useController({ name, control, rules, defaultValue });

    // Local state to manage the input value - Default to field's value
    // Get the value's name from the id
    const defaultName = useMemo(() => {
        const option = dropdownOptions?.find((option) => option.id === field.value);
        return option?.name;
    }, [dropdownOptions]);

    const [localValue, setLocalValue] = useState<string | undefined>(defaultName);
    const [filteredOptions, setFilteredOptions] = useState(dropdownOptions);

    const error = get(errors, name);

    const errorMessage = error ? (error.message.length > 0 ? error.message : 'This field is required') : undefined;

    // If the fields value is a empty string, set it to null
    useEffect(() => {
        if (!field.value) {
            field.onChange(null);
        }
    }, [field.value, field.onChange]);

    // On input function to set the value of the field
    // This onInput is for the Combobox text input, so this handles the text input changes. And that value is handled by localValue
    const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const value = ev.target.value.trim();
        const matches = dropdownOptions?.filter(({ name }) => name.toLowerCase().indexOf(value.toLowerCase()) === 0);
        setFilteredOptions(matches);
        if (value.length && matches && matches.length < 1) {
            setLocalValue(value);
        } else {
            setLocalValue(undefined);
        }
    };

    const onOptionSelect = (_event: SelectionEvents, data: OptionOnSelectData) => {
        // Early return if the data is undefined and empty this is only happening when the user clears the input
        if (data.optionText === undefined && data.optionValue === undefined) {
            // This only needs to return because the clear button handler is where we make changes.
            return;
        }

        const matchingOption =
            data.optionText &&
            dropdownOptions?.includes({
                name: data.optionText,
                id: data.optionValue ?? '',
            });

        if (matchingOption) {
            setLocalValue(undefined);
        } else {
            setLocalValue(data.optionText);
            field.onChange(data.optionValue);
        }
    };

    const onClearButtonClick = () => {
        // Set the field and local value to blank strings. Undefined will cause React render errors.
        field.onChange('');
        setLocalValue('');
        // Default the options back to unfiltered
        setFilteredOptions(dropdownOptions);
    };

    return (
        <InputWrapper>
            <Field label={label} validationMessage={errorMessage} required={(rules?.required as boolean) ?? false}>
                {!optionsLoaded ? (
                    <Input value="Loading Options" contentBefore={<Spinner size="tiny" />} disabled />
                ) : (
                    <Combobox
                        appearance="filled-darker"
                        disabled={props.disabled}
                        placeholder={label}
                        onChange={onChange}
                        onOptionSelect={onOptionSelect}
                        selectedOptions={[field.value]} // Set the selected option based on the field value
                        value={localValue}
                        freeform
                        clearable={true}
                        clearIcon={{ onClick: onClearButtonClick }}
                    >
                        {/* Below is my hack of just wrapping it in a div. Which works but doesn't feel like the right way to solve this. */}
                        {/* Watch https://github.com/microsoft/fluentui/discussions/33266 for updates from team. */}
                        <div style={{ maxHeight: '40vh', overflow: 'auto' }}>
                            {filteredOptions?.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {option.name}
                                </Option>
                            ))}
                        </div>
                    </Combobox>
                )}
            </Field>
            {/* <p>field val {field.value}</p>
      <p>loc val {localValue}</p> */}
        </InputWrapper>
    );
};

export default OptionTypeCombo;
