import { Address } from '../api';

export const addressPrinter = (address: Address | null | undefined): string => {
    if (!address) {
        return 'Address was entered incorrectly';
    }
    if (!address.streetLine) {
        return '';
    }
    if (!address.streetLine2) {
        return `${address.streetLine}, ${address.city}, ${address.state}, ${address.zip}`;
    } else {
        return `${address.streetLine} ${address.streetLine2}, ${address.city}, ${address.state}, ${address.zip}`;
    }
};
