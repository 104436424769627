import { Field, Input, InputProps, useId } from "@fluentui/react-components";
import {
  FieldValues,
  get,
  useController,
  UseControllerProps,
} from "react-hook-form";
import InputWrapper from "../layout/InputWrapper";

type EmailInputProps<T extends FieldValues> = UseControllerProps<T> &
  InputProps & {
    label?: string;
  };

const validateEmailString = (value: string): boolean => {
  const match = value.match(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
  );
  return match !== null;
};

const validateEmails = (value: string | undefined | null): boolean | string => {
  // If no value, return true. This is because this test should only run if there is a string value to validate.
  // If there is no value, then the required test will fail, and the user will be prompted to enter a value.
  if (!value) {
    return true;
  }
  const strippedValues = value
    .trim()
    .toLowerCase()
    .replace(/[\s,;]/g, ",")
    .split(",")
    .filter((email: string) => email.length > 0);
  const validEmails = strippedValues.filter((email: string) =>
    validateEmailString(email),
  );
  return validEmails.length === strippedValues.length
    ? true
    : "Looks like your email address isn't valid.";
};

const EmailInput = <T extends FieldValues>(props: EmailInputProps<T>) => {
  const { name, control, rules, defaultValue, label, ...rest } = props;
  const fieldId = useId(name);

  const {
    field,
    formState: { errors },
  } = useController({
    name,
    control,
    rules: { ...rules, validate: validateEmails },
    defaultValue,
  });

  const error = get(errors, name);

  const errorMessage = error
    ? error.message.length > 0
      ? error.message
      : "This field is required"
    : undefined;

  return (
    <InputWrapper>
      <Field
        id={fieldId}
        validationMessage={errorMessage}
        label={label}
        required={(rules?.required as boolean) ?? false}
      >
        <Input {...field} {...rest} appearance="filled-darker" />
      </Field>
    </InputWrapper>
  );
};

export default EmailInput;
