import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Button,
    Card,
    createTableColumn,
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridRow,
    makeStyles,
    MessageBar,
    MessageBarBody,
    MessageBarTitle,
    Spinner,
    TableCellLayout,
    TableColumnDefinition,
    Text,
    Title2,
} from '@fluentui/react-components';
import { AddSquareRegular, ArrowClockwise24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientListingResponse } from '../../api/api';
import { useClientsGet } from '../../api/hooks/ClientsApi';
import { generateDateLabel } from '../../helpers/generateDateLabel';
import PaginationToolbar from '../common/PaginationToolbar';
import Container from '../layout/Container';
import ResponsiveRowWrapper from '../layout/ResponsiveRowWrapper';
import Stack from '../layout/Stack';

const useStyles = makeStyles({
    accordionMobile: {
        display: 'block',
        width: '100%',
        '@media (min-width: 573px)': {
            display: 'none',
        },
    },
    dataGridDesktop: {
        display: 'none',
        '@media (min-width: 573px)': {
            display: 'block',
            width: '100%',
        },
    },
    tobBarContainer: {
        justifyContent: 'space-between',
        paddingBottom: '1em',
    },
    fullWidth: {
        width: '100%',
    },
    almostFullWidth: {
        width: '90%',
    },
    textPaddingBottom: {
        paddingBottom: '1rem',
    },
    desktopDataGrid: {
        minWidth: '500px',
        minHeight: '500px',
    },
    bottomBorder: {
        borderBottom: '1px solid grey',
    },
    customMargins: {
        marginTop: 0,
        marginBottom: '1rem',
        marginLeft: '.5rem',
    },
});

const APIcolumns: TableColumnDefinition<ClientListingResponse>[] = [
    createTableColumn<ClientListingResponse>({
        columnId: 'clientId',
        renderHeaderCell: () => {
            return 'Client Id';
        },
        renderCell: (item) => {
            return <TableCellLayout>{item.clientId}</TableCellLayout>;
        },
    }),
    createTableColumn<ClientListingResponse>({
        columnId: 'name',
        renderHeaderCell: () => {
            return 'Name';
        },
        renderCell: (item) => {
            return <TableCellLayout>{item.name}</TableCellLayout>;
        },
    }),
    createTableColumn<ClientListingResponse>({
        columnId: 'clientType',
        renderHeaderCell: () => {
            return 'Client Type';
        },
        renderCell: (item) => {
            return <TableCellLayout>{item.clientType}</TableCellLayout>;
        },
    }),
    createTableColumn<ClientListingResponse>({
        columnId: 'officeName',
        renderHeaderCell: () => {
            return 'Office Name';
        },
        renderCell: (item) => {
            return <TableCellLayout>{item.officeName}</TableCellLayout>;
        },
    }),
    createTableColumn<ClientListingResponse>({
        columnId: 'BusinessUnitName',
        renderHeaderCell: () => {
            return 'Business Unit';
        },
        renderCell: (item) => {
            return <TableCellLayout>{item?.businessUnitName}</TableCellLayout>;
        },
    }),
    createTableColumn<ClientListingResponse>({
        columnId: 'createdAt',
        renderHeaderCell: () => {
            return 'Created At';
        },
        renderCell: (item) => {
            return generateDateLabel(item.createdAt);
        },
    }),
    createTableColumn<ClientListingResponse>({
        columnId: 'createdBy',
        renderHeaderCell: () => {
            return 'Created By';
        },
        renderCell: (item) => {
            return <TableCellLayout>{item.createdBy ?? '--'}</TableCellLayout>;
        },
    }),
];

export const RecentClientsTable = () => {
    const [page, setPage] = React.useState(0);
    const styles = useStyles();
    const navigate = useNavigate();
    // const [sortState, setSortState] = React.useState<
    //   Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]
    // >({
    //   sortColumn: "createdAt",
    //   sortDirection: "descending",
    // });
    // const onSortChange: DataGridProps["onSortChange"] = (e, nextSortState) => {
    //   setSortState(nextSortState);
    // };

    const {
        data: clientItemsAPI,
        isLoading,
        isFetched,
        isFetching,
        isError,
        error,
        refetch,
    } = useClientsGet({ page, pageSize: 10 });

    const handleRefetchClientList = () => {
        refetch();
    };

    const canRender = React.useMemo(() => Boolean(isLoading === false && isFetched === true), [isLoading, isFetched]);

    return (
        <Container>
            <ResponsiveRowWrapper className={styles.tobBarContainer}>
                <Title2>Recent Clients</Title2>

                <Stack>
                    <Button appearance="primary" icon={<AddSquareRegular />} onClick={() => navigate('/FormPage')}>
                        Create Client
                    </Button>
                    {isFetching ? (
                        <Button appearance="primary" onClick={handleRefetchClientList}>
                            <Spinner size="tiny" /> &nbsp; Refresh Client List
                        </Button>
                    ) : (
                        <Button
                            appearance="primary"
                            icon={<ArrowClockwise24Regular />}
                            onClick={handleRefetchClientList}
                        >
                            Refresh Client List
                        </Button>
                    )}
                </Stack>
            </ResponsiveRowWrapper>
            <Stack flexDirection="column">
                {/* <SearchBox placeholder="Client Name, Partner, Rainmaker, etc" className={styles.fullWidth} /> */}
                {/* mobile */}
                <Card className={styles.accordionMobile}>
                    {isError ? (
                        <MessageBar key="submitError" intent="error">
                            <MessageBarBody>
                                <MessageBarTitle>Error Retrieving Clients - </MessageBarTitle>
                                {error.toString()}
                            </MessageBarBody>
                        </MessageBar>
                    ) : (
                        <Accordion collapsible>
                            {clientItemsAPI?.map((client, index) => {
                                const header = (
                                    <Stack justifyContent="space-between" className={styles.almostFullWidth}>
                                        <Text className={styles.textPaddingBottom}>{client.name}</Text>
                                        {/* <Text>{client.id}</Text> */}
                                    </Stack>
                                );
                                return (
                                    <AccordionItem value={client.id} key={index} className={styles.bottomBorder}>
                                        <AccordionHeader inline={true} className={styles.fullWidth}>
                                            {header}
                                        </AccordionHeader>
                                        <AccordionPanel>
                                            <Stack flexDirection="column" className={styles.customMargins}>
                                                <Stack justifyContent="space-between" className={styles.fullWidth}>
                                                    <div>
                                                        <TableCellLayout>{client.clientType}</TableCellLayout>
                                                    </div>
                                                    <div>Office: {client.officeName}</div>
                                                </Stack>
                                                <Stack justifyContent="center" className={styles.fullWidth}>
                                                    Created At: {generateDateLabel(client.createdAt)}
                                                </Stack>
                                            </Stack>
                                        </AccordionPanel>
                                    </AccordionItem>
                                );
                            })}
                        </Accordion>
                    )}
                </Card>

                {/* desktop */}
                <Card className={styles.dataGridDesktop}>
                    {isError ? (
                        <MessageBar key="submitError" intent="error">
                            <MessageBarBody>
                                <MessageBarTitle>Error Retrieving Clients - </MessageBarTitle>
                                {error.toString()}
                            </MessageBarBody>
                        </MessageBar>
                    ) : (
                        <>
                            <DataGrid
                                items={clientItemsAPI ?? []}
                                columns={APIcolumns}
                                sortable
                                // sortState={sortState}
                                // onSortChange={onSortChange}
                                className={styles.desktopDataGrid}
                            >
                                <DataGridHeader>
                                    <DataGridRow>
                                        {({ renderHeaderCell }) => (
                                            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                        )}
                                    </DataGridRow>
                                </DataGridHeader>
                                <DataGridBody<ClientListingResponse>>
                                    {({ item, rowId }) => (
                                        <DataGridRow<ClientListingResponse> key={rowId}>
                                            {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
                                        </DataGridRow>
                                    )}
                                </DataGridBody>
                            </DataGrid>
                            <PaginationToolbar page={page} setPage={setPage} />
                        </>
                    )}
                </Card>
            </Stack>
        </Container>
    );
};
