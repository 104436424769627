import { ReactNode } from "react";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  inputContainer: {
    minWidth: "30%",
  },
});

interface InputWrapperProps {
  children: ReactNode;
}

const InputWrapper = ({ children }: InputWrapperProps) => {
  const styles = useStyles();

  return <div className={styles.inputContainer}>{children}</div>;
};

export default InputWrapper;
