import { useTeamsUserCredential } from '@microsoft/teamsfx-react';
import axios from 'axios';
import { authConfig } from '../../components/App';
import { ClientsApi, UserApi, OptionsApi } from '../api';
// Url
const backendURL = process.env.REACT_APP_BACKEND_URL;

export const useClientOnboardingApi = () => {
    const credential = useTeamsUserCredential(authConfig);
    const axiosInstance = axios.create({
        baseURL: backendURL,
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
    });

    // Add auth token
    axiosInstance.interceptors.request.use(async (request) => {
        if (credential?.teamsUserCredential) {
            const userInfo = await credential.teamsUserCredential.getUserInfo();
            const token = await credential.teamsUserCredential.getToken('');
            console.log('Adding Token to Request', token);
            request.headers['Authorization'] = `Bearer ${token?.token}`;
            request.headers['x-user-email'] = userInfo.preferredUserName;
        }
        return request;
    });

    // * Try to refresh token on 401 errors
    // axiosInstance.interceptors.response.use(
    //     (response) => {
    //         return response;
    //     },
    //     async (error) => {
    //         const token = getToken();
    //         const refreshToken = getRefreshToken();
    //         const originalRequest = error.config;

    //         if (error.response?.status === 401 && !originalRequest._retry) {
    //             originalRequest._retry = true;

    //             try {
    //                 const response = await axios.post(`${backendURL}/users/refresh`, {
    //                     expiredToken: token,
    //                     refreshTokenId: refreshToken,
    //                 });
    //                 const newToken = response?.data;
    //                 setToken(newToken);

    //                 originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

    //                 return originalRequest ? axiosInstance(originalRequest) : Promise.reject(error);
    //             } catch (error) {
    //                 console.error('Error refreshing token', { error });
    //                 logout();
    //             }
    //         }
    //         return Promise.reject(error);
    //     },
    // );
    // return instance;

    return {
        ClientsApi: new ClientsApi(undefined, backendURL, axiosInstance),
        UserApi: new UserApi(undefined, backendURL, axiosInstance),
        OptionsApi: new OptionsApi(undefined, backendURL, axiosInstance),
    };
};
