import {
  Combobox,
  Field,
  OptionOnSelectData,
  SelectionEvents,
  useComboboxFilter,
} from "@fluentui/react-components";
import { useState } from "react";
import {
  FieldValues,
  get,
  useController,
  UseControllerProps,
} from "react-hook-form";

type State = {
  name: string;
  abbreviation: string;
};

const statesArray: State[] = [
  { name: "Alabama", abbreviation: "AL" },
  { name: "Alaska", abbreviation: "AK" },
  { name: "Arizona", abbreviation: "AZ" },
  { name: "Arkansas", abbreviation: "AR" },
  { name: "California", abbreviation: "CA" },
  { name: "Colorado", abbreviation: "CO" },
  { name: "Connecticut", abbreviation: "CT" },
  { name: "Delaware", abbreviation: "DE" },
  { name: "Florida", abbreviation: "FL" },
  { name: "Georgia", abbreviation: "GA" },
  { name: "Hawaii", abbreviation: "HI" },
  { name: "Idaho", abbreviation: "ID" },
  { name: "Illinois", abbreviation: "IL" },
  { name: "Indiana", abbreviation: "IN" },
  { name: "Iowa", abbreviation: "IA" },
  { name: "Kansas", abbreviation: "KS" },
  { name: "Kentucky", abbreviation: "KY" },
  { name: "Louisiana", abbreviation: "LA" },
  { name: "Maine", abbreviation: "ME" },
  { name: "Maryland", abbreviation: "MD" },
  { name: "Massachusetts", abbreviation: "MA" },
  { name: "Michigan", abbreviation: "MI" },
  { name: "Minnesota", abbreviation: "MN" },
  { name: "Mississippi", abbreviation: "MS" },
  { name: "Missouri", abbreviation: "MO" },
  { name: "Montana", abbreviation: "MT" },
  { name: "Nebraska", abbreviation: "NE" },
  { name: "Nevada", abbreviation: "NV" },
  { name: "New Hampshire", abbreviation: "NH" },
  { name: "New Jersey", abbreviation: "NJ" },
  { name: "New Mexico", abbreviation: "NM" },
  { name: "New York", abbreviation: "NY" },
  { name: "North Carolina", abbreviation: "NC" },
  { name: "North Dakota", abbreviation: "ND" },
  { name: "Ohio", abbreviation: "OH" },
  { name: "Oklahoma", abbreviation: "OK" },
  { name: "Oregon", abbreviation: "OR" },
  { name: "Pennsylvania", abbreviation: "PA" },
  { name: "Rhode Island", abbreviation: "RI" },
  { name: "South Carolina", abbreviation: "SC" },
  { name: "South Dakota", abbreviation: "SD" },
  { name: "Tennessee", abbreviation: "TN" },
  { name: "Texas", abbreviation: "TX" },
  { name: "Utah", abbreviation: "UT" },
  { name: "Vermont", abbreviation: "VT" },
  { name: "Virginia", abbreviation: "VA" },
  { name: "Washington", abbreviation: "WA" },
  { name: "West Virginia", abbreviation: "WV" },
  { name: "Wisconsin", abbreviation: "WI" },
  { name: "Wyoming", abbreviation: "WY" },
];

type filterOptionType = {
  children: React.ReactNode;
  value: string;
};

type StateComboProps<T extends FieldValues> = UseControllerProps<T> & {
  className?: string;
};

const StateCombo = <T extends FieldValues>(props: StateComboProps<T>) => {
  const { name, control, rules, defaultValue, className } = props;

  const {
    field,
    formState: { errors },
  } = useController({ name, control, rules, defaultValue });
  // Local state to manage the input value - Default to field's value
  // Get the value's name from the id
  const nameFromId = statesArray.find(
    (option) => option.abbreviation === field.value,
  )?.name;
  const [localValue, setLocalValue] = useState(nameFromId || "");

  const error = get(errors, name);
  const errorMessage = error
    ? error.message.length > 0
      ? error.message
      : "This field is required"
    : undefined;

  // On input function to set the value of the field
  // This onInput is for the Combobox text input, so this handles the text input changes. And that value is handled by localValue
  const onInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(ev.target.value);
  };

  const onOptionSelect = (
    _event: SelectionEvents,
    data: OptionOnSelectData,
  ) => {
    // Update the field value with the selected option
    field.onChange(data.optionValue);
    // Update local state with the selected option
    setLocalValue(data.optionText ?? "");
  };

  const stateChildren: filterOptionType[] = statesArray.map((option) => {
    return { children: option.name, value: option.abbreviation };
  });

  const children = useComboboxFilter(localValue, stateChildren, {
    noOptionsMessage: "No States match your search",
    filter: (option, query) =>
      option.toLowerCase().startsWith(query.toLowerCase()),
  });

  return (
    <div className={className}>
      <Field
        label="State"
        validationMessage={errorMessage}
        className={className}
        required={(rules?.required as boolean) ?? false}
      >
        <Combobox
          placeholder="State"
          onInput={onInput}
          onOptionSelect={onOptionSelect}
          value={localValue}
          selectedOptions={[field.value]} // Set the selected option based on the field value
          appearance="filled-darker"
          // minWidth is set to 100% to make the combobox fill the width of the field, and override the default that causes the input to spill out
          style={{ minWidth: "100%" }}
          positioning={{
            autoSize: "height-always",
            fallbackPositions: ["below"],
          }}
        >
          {children}
        </Combobox>
      </Field>
    </div>
  );
};

export default StateCombo;
