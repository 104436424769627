/* tslint:disable */
/* eslint-disable */
/**
 * ATA-ClientOnboarding
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'streetLine'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'streetLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'zip'?: string;
}
/**
 * 
 * @export
 * @interface AdministrativeSection
 */
export interface AdministrativeSection {
    /**
     * 
     * @type {string}
     * @memberof AdministrativeSection
     */
    'yearEnd': string;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeSection
     */
    'lineOfBusinessId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeSection
     */
    'primaryPartnerId': string;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeSection
     */
    'rainmakerId': string;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeSection
     */
    'rainmakerDate': string;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeSection
     */
    'billerId': string;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeSection
     */
    'businessCode'?: string;
}
/**
 * 
 * @export
 * @interface BusinessSection
 */
export interface BusinessSection {
    /**
     * 
     * @type {string}
     * @memberof BusinessSection
     */
    'businessName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessSection
     */
    'dateIncorporated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BusinessSection
     */
    'businessCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessSection
     */
    'principalBusinessActivity'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessSection
     */
    'principalProductService'?: string;
}
/**
 * 
 * @export
 * @interface ClientCreationResponse
 */
export interface ClientCreationResponse {
    /**
     * Unique identifier for the client in GUID format.
     * @type {string}
     * @memberof ClientCreationResponse
     */
    'id': string;
    /**
     * The name of the client.
     * @type {string}
     * @memberof ClientCreationResponse
     */
    'name': string;
    /**
     * A unique string identifier for the client.
     * @type {string}
     * @memberof ClientCreationResponse
     */
    'clientId': string;
}
/**
 * 
 * @export
 * @interface ClientListingResponse
 */
export interface ClientListingResponse {
    /**
     * Unique identifier for the client in GUID format.
     * @type {string}
     * @memberof ClientListingResponse
     */
    'id': string;
    /**
     * The name of the client.
     * @type {string}
     * @memberof ClientListingResponse
     */
    'name': string;
    /**
     * 
     * @type {ClientType}
     * @memberof ClientListingResponse
     */
    'clientType': ClientType;
    /**
     * A unique string identifier for the client.
     * @type {string}
     * @memberof ClientListingResponse
     */
    'clientId': string;
    /**
     * The name of the office the client is associated with.
     * @type {string}
     * @memberof ClientListingResponse
     */
    'officeName': string;
    /**
     * The name of the Business Unit the client is associated with.
     * @type {string}
     * @memberof ClientListingResponse
     */
    'businessUnitName'?: string;
    /**
     * The date and time when the client was created.
     * @type {string}
     * @memberof ClientListingResponse
     */
    'createdAt'?: string;
    /**
     * The user or system that created the client.
     * @type {string}
     * @memberof ClientListingResponse
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientListingResponse
     */
    'createdById'?: string | null;
}


/**
 * 
 * @export
 * @interface ClientOnboardingForm
 */
export interface ClientOnboardingForm {
    /**
     * 
     * @type {ClientType}
     * @memberof ClientOnboardingForm
     */
    'clientType': ClientType;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboardingForm
     */
    'clientSubTypeId'?: string | null;
    /**
     * 
     * @type {GeneralSection}
     * @memberof ClientOnboardingForm
     */
    'generalSection': GeneralSection;
    /**
     * 
     * @type {ContactSection}
     * @memberof ClientOnboardingForm
     */
    'contactSection': ContactSection;
    /**
     * 
     * @type {AdministrativeSection}
     * @memberof ClientOnboardingForm
     */
    'adminSection': AdministrativeSection;
}


/**
 * Enumeration for the different types of clients.
 * @export
 * @enum {string}
 */

export enum ClientType {
    Individual = 'Individual',
    Corporation = 'Corporation',
    EmployeePlan = 'Employee Plan',
    NonProfit = 'Non-Profit',
    Fiduciary = 'Fiduciary',
    Partnership = 'Partnership',
    SoleProprietorship = 'SoleProprietorship',
    Other = 'Other',
    Staff = 'Staff'
}


/**
 * 
 * @export
 * @interface ContactSection
 */
export interface ContactSection {
    /**
     * 
     * @type {Address}
     * @memberof ContactSection
     */
    'mainAddress': Address;
    /**
     * 
     * @type {Address}
     * @memberof ContactSection
     */
    'mailingAddress'?: Address;
    /**
     * 
     * @type {string}
     * @memberof ContactSection
     */
    'mainPhone': string;
    /**
     * 
     * @type {string}
     * @memberof ContactSection
     */
    'mainEmail': string;
    /**
     * 
     * @type {string}
     * @memberof ContactSection
     */
    'spouseEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSection
     */
    'mobilePhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSection
     */
    'businessEmail'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralSection
 */
export interface GeneralSection {
    /**
     * Unique identifier for the business unit in GUID format.
     * @type {string}
     * @memberof GeneralSection
     */
    'businessUnitId': string;
    /**
     * Unique identifier for the office in GUID format.
     * @type {string}
     * @memberof GeneralSection
     */
    'officeId': string;
    /**
     * 
     * @type {IndividualSection}
     * @memberof GeneralSection
     */
    'individualSection': IndividualSection;
    /**
     * 
     * @type {BusinessSection}
     * @memberof GeneralSection
     */
    'businessSection': BusinessSection;
}
/**
 * 
 * @export
 * @interface IndividualSection
 */
export interface IndividualSection {
    /**
     * 
     * @type {string}
     * @memberof IndividualSection
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualSection
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IndividualSection
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualSection
     */
    'spouseFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualSection
     */
    'spouseMiddleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IndividualSection
     */
    'spouseLastName'?: string;
}
/**
 * 
 * @export
 * @interface OptionCodeResponse
 */
export interface OptionCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof OptionCodeResponse
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OptionCodeResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OptionCodeResponse
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface OptionGuidResponse
 */
export interface OptionGuidResponse {
    /**
     * 
     * @type {string}
     * @memberof OptionGuidResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OptionGuidResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OptionGuidResponse
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionGuidResponse
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * User ID
     * @type {string}
     * @memberof UserResponse
     */
    'id'?: string;
}


// This is a comment that shows that the template is working - Parker

/**
 * ClientsApi - axios parameter creator
 * @export
 */
export const ClientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve a list of clients
         * @param {string} [sort] Field by which to sort results
         * @param {ClientsGetOrderEnum} [order] Order of sorting, either \&#39;asc\&#39; for ascending or \&#39;desc\&#39; for descending
         * @param {string} [search] Criteria for searching clients
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of clients per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsGet: async (sort?: string, order?: ClientsGetOrderEnum, search?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new client
         * @param {ClientOnboardingForm} clientOnboardingForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsPost: async (clientOnboardingForm: ClientOnboardingForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientOnboardingForm' is not null or undefined
            assertParamExists('clientsPost', 'clientOnboardingForm', clientOnboardingForm)
            const localVarPath = `/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientOnboardingForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientsApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve a list of clients
         * @param {string} [sort] Field by which to sort results
         * @param {ClientsGetOrderEnum} [order] Order of sorting, either \&#39;asc\&#39; for ascending or \&#39;desc\&#39; for descending
         * @param {string} [search] Criteria for searching clients
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of clients per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsGet(sort?: string, order?: ClientsGetOrderEnum, search?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientListingResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsGet(sort, order, search, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new client
         * @param {ClientOnboardingForm} clientOnboardingForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsPost(clientOnboardingForm: ClientOnboardingForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsPost(clientOnboardingForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientsApiFp(configuration)
    return {
        /**
         * Retrieve a list of clients
         * @param {ClientsApiClientsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsGet(requestParameters: ClientsApiClientsGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ClientListingResponse>> {
            return localVarFp.clientsGet(requestParameters.sort, requestParameters.order, requestParameters.search, requestParameters.page, requestParameters.pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new client
         * @param {ClientsApiClientsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsPost(requestParameters: ClientsApiClientsPostRequest, options?: AxiosRequestConfig): AxiosPromise<ClientCreationResponse> {
            return localVarFp.clientsPost(requestParameters.clientOnboardingForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for clientsGet operation in ClientsApi.
 * @export
 * @interface ClientsApiClientsGetRequest
 */
export interface ClientsApiClientsGetRequest {
    /**
     * Field by which to sort results
     * @type {string}
     * @memberof ClientsApiClientsGet
     */
    readonly sort?: string

    /**
     * Order of sorting, either \&#39;asc\&#39; for ascending or \&#39;desc\&#39; for descending
     * @type {'asc' | 'desc'}
     * @memberof ClientsApiClientsGet
     */
    readonly order?: ClientsGetOrderEnum

    /**
     * Criteria for searching clients
     * @type {string}
     * @memberof ClientsApiClientsGet
     */
    readonly search?: string

    /**
     * Page number for pagination
     * @type {number}
     * @memberof ClientsApiClientsGet
     */
    readonly page?: number

    /**
     * Number of clients per page
     * @type {number}
     * @memberof ClientsApiClientsGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for clientsPost operation in ClientsApi.
 * @export
 * @interface ClientsApiClientsPostRequest
 */
export interface ClientsApiClientsPostRequest {
    /**
     * 
     * @type {ClientOnboardingForm}
     * @memberof ClientsApiClientsPost
     */
    readonly clientOnboardingForm: ClientOnboardingForm
}

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
    /**
     * Retrieve a list of clients
     * @param {ClientsApiClientsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsGet(requestParameters: ClientsApiClientsGetRequest = {}, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsGet(requestParameters.sort, requestParameters.order, requestParameters.search, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new client
     * @param {ClientsApiClientsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsPost(requestParameters: ClientsApiClientsPostRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsPost(requestParameters.clientOnboardingForm, options).then((request) => request(this.axios, this.basePath));
    }
}

export const ClientsApiFunctionKeys: Record<keyof ClientsApi, string> = {
    "clientsGet": "ClientsApi.clientsGet",
    "clientsPost": "ClientsApi.clientsPost",
}

/**
  * @export
  * @enum {string}
  */
export enum ClientsGetOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}



// This is a comment that shows that the template is working - Parker

/**
 * OptionsApi - axios parameter creator
 * @export
 */
export const OptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get Client Type options
         * @param {ClientType} clientType Filter options by client type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptionsClientType: async (clientType: ClientType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientType' is not null or undefined
            assertParamExists('getOptionsClientType', 'clientType', clientType)
            const localVarPath = `/options/clientType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clientType !== undefined) {
                localVarQueryParameter['clientType'] = clientType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Business Code options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsBusinessCodeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/options/businessCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Business Unit options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsBusinessUnitGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/options/businessUnit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Line of Business options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsLineOfBusinessGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/options/lineOfBusiness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Office options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsOfficeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/options/office`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Staff options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsStaffGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/options/staff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OptionsApi - functional programming interface
 * @export
 */
export const OptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get Client Type options
         * @param {ClientType} clientType Filter options by client type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOptionsClientType(clientType: ClientType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionGuidResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOptionsClientType(clientType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Business Code options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionsBusinessCodeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionGuidResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionsBusinessCodeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Business Unit options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionsBusinessUnitGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionGuidResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionsBusinessUnitGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Line of Business options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionsLineOfBusinessGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionGuidResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionsLineOfBusinessGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Office options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionsOfficeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionGuidResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionsOfficeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Staff options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionsStaffGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionGuidResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionsStaffGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OptionsApi - factory interface
 * @export
 */
export const OptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OptionsApiFp(configuration)
    return {
        /**
         * Get Client Type options
         * @param {OptionsApiGetOptionsClientTypeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptionsClientType(requestParameters: OptionsApiGetOptionsClientTypeRequest, options?: AxiosRequestConfig): AxiosPromise<Array<OptionGuidResponse>> {
            return localVarFp.getOptionsClientType(requestParameters.clientType, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Business Code options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsBusinessCodeGet(options?: AxiosRequestConfig): AxiosPromise<Array<OptionGuidResponse>> {
            return localVarFp.optionsBusinessCodeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Business Unit options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsBusinessUnitGet(options?: AxiosRequestConfig): AxiosPromise<Array<OptionGuidResponse>> {
            return localVarFp.optionsBusinessUnitGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Line of Business options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsLineOfBusinessGet(options?: AxiosRequestConfig): AxiosPromise<Array<OptionGuidResponse>> {
            return localVarFp.optionsLineOfBusinessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Office options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsOfficeGet(options?: AxiosRequestConfig): AxiosPromise<Array<OptionGuidResponse>> {
            return localVarFp.optionsOfficeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Staff options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsStaffGet(options?: AxiosRequestConfig): AxiosPromise<Array<OptionGuidResponse>> {
            return localVarFp.optionsStaffGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getOptionsClientType operation in OptionsApi.
 * @export
 * @interface OptionsApiGetOptionsClientTypeRequest
 */
export interface OptionsApiGetOptionsClientTypeRequest {
    /**
     * Filter options by client type
     * @type {ClientType}
     * @memberof OptionsApiGetOptionsClientType
     */
    readonly clientType: ClientType
}

/**
 * OptionsApi - object-oriented interface
 * @export
 * @class OptionsApi
 * @extends {BaseAPI}
 */
export class OptionsApi extends BaseAPI {
    /**
     * Get Client Type options
     * @param {OptionsApiGetOptionsClientTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public getOptionsClientType(requestParameters: OptionsApiGetOptionsClientTypeRequest, options?: AxiosRequestConfig) {
        return OptionsApiFp(this.configuration).getOptionsClientType(requestParameters.clientType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Business Code options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public optionsBusinessCodeGet(options?: AxiosRequestConfig) {
        return OptionsApiFp(this.configuration).optionsBusinessCodeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Business Unit options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public optionsBusinessUnitGet(options?: AxiosRequestConfig) {
        return OptionsApiFp(this.configuration).optionsBusinessUnitGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Line of Business options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public optionsLineOfBusinessGet(options?: AxiosRequestConfig) {
        return OptionsApiFp(this.configuration).optionsLineOfBusinessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Office options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public optionsOfficeGet(options?: AxiosRequestConfig) {
        return OptionsApiFp(this.configuration).optionsOfficeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Staff options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public optionsStaffGet(options?: AxiosRequestConfig) {
        return OptionsApiFp(this.configuration).optionsStaffGet(options).then((request) => request(this.axios, this.basePath));
    }
}

export const OptionsApiFunctionKeys: Record<keyof OptionsApi, string> = {
    "getOptionsClientType": "OptionsApi.getOptionsClientType",
    "optionsBusinessCodeGet": "OptionsApi.optionsBusinessCodeGet",
    "optionsBusinessUnitGet": "OptionsApi.optionsBusinessUnitGet",
    "optionsLineOfBusinessGet": "OptionsApi.optionsLineOfBusinessGet",
    "optionsOfficeGet": "OptionsApi.optionsOfficeGet",
    "optionsStaffGet": "OptionsApi.optionsStaffGet",
}




// This is a comment that shows that the template is working - Parker

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get User Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Get User Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Get User Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Get User Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userGet(options).then((request) => request(this.axios, this.basePath));
    }
}

export const UserApiFunctionKeys: Record<keyof UserApi, string> = {
    "userGet": "UserApi.userGet",
}



